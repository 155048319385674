//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableMixin from "./mixin";
export default {
    mixins: [tableMixin],
    name: "ChildShoesTable",
    data: function () {
        return {
            tableData: {
                head: {
                    inch: [this.$t('sizeTable.shoes.table.headers.centimeter.title'), '6.5"', '6.7"','6.9"','7.0"','7.2"','7.4"','7.5"','7.7"','7.9"','8.0"','8.2"','8.3"'],
                    centimeter: [this.$t('sizeTable.shoes.table.headers.centimeter.title'),'16.6 cm', '17 cm','17.4 cm', '17.8 cm', '18.3 cm', '18.7 cm', '19.1 cm', '19.5 cm', '20 cm','20.4 cm', '20.8 cm', '21.2 cm',]
                },
                body:  [
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle1'), '10k', '10.5k', '11k', '11.5k', '12k', '12.5k', '13k', '13.5k', '1', '1.5', '2', '2.5',],
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle2'), '10.5k', '11k', '11.5k', '12k', '12.5k', '13k', '13.5k', '1', '1.5', '2', '2.5', '3',],
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle3'), '28', '28.5', '29', '30', '30.5', '31', '31.5', '32', '33', '33.5', '34', '35',]
                ]
            }
        }
    }

}
