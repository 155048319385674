//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableMixin from "./mixin";
export default {
    mixins: [tableMixin],
    name: "TeenShoesTable",
    data: function () {
        return {
            tableData: {
                head: {
                    inch: [this.$t('sizeTable.shoes.table.headers.centimeter.title'), '8.5"', '8.7"', '8.9"', '9.0"', '9.2"', '9.4"', '9.5"', '9.7"', '9.8"',],
                    centimeter: [this.$t('sizeTable.shoes.table.headers.centimeter.title'),'21.6 cm', '22.1 cm', '22.5 cm', '22.9 cm', '23.3 cm', '23.8 cm', '24.2 cm', '24.6 cm', '25 cm']
                },
                body:  [
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle1'), '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7',],
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle2'), '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5',],
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle3'), '35.5', '36', '36 2/3', '37 1/3', '38', '38 2/3', '39 1/3', '40', '40 2/3']
                ]
            }
        }
    }

}
