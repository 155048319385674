//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableMixin from "./mixin";
export default {
    mixins: [tableMixin],
    name: "KidShoesTable",
    data: function () {
        return {
            tableData: {
                head: {
                    inch: [this.$t('sizeTable.shoes.table.headers.centimeter.title'), '3.2"', '3.5"', '3.9"', '4.2"', '4.5"', '4.8"', '5.0"', '5.2"', '5.4"', '5.5"', '5.7"', '5.9"', '6.0"', '6.2"', '6.3"',],
                    centimeter: [this.$t('sizeTable.shoes.table.headers.centimeter.title'), '8.1 cm', '9 cm', '9.8 cm', '10.6 cm', '11.5 cm', '12.3 cm', '12.8 cm', '13.2 cm', '13.6 cm', '14 cm', '14.5 cm', '14.9 cm', '15.3 cm', '15.7 cm', '16.1 cm',]
                },
                body:  [
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle1'), '0k', '1k', '2k', '3k', '4k', '5k', '5.5k', '6k', '6.5k', '7k', '7.5k', '8k', '8.5k', '9k', '9.5k',],
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle2'), '1k', '2k', '3k', '4k', '5k', '5.5k', '6k', '6.5k', '7k', '7.5k', '8k', '8.5k', '9k', '9.5k', '10k'],
                    [this.$t('sizeTable.childrenShoes.table.body.subtitle3'), '16', '17', '18', '19', '20', '21', '22', '23', '23.5', '24', '25', '25.5', '26', '26.5', '27',],
                ]
            }
        }
    }

}
