export default {
    props: {
        isInch: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter;
        },
        tableBodyInchData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter;
        },
        tableBodyData() {
            return this.tableData.body;
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 678) + "px";
        },

        mobileDown() {
            return this.mobile || this.mobileSmall;
        },
        mobileSmall() {
            return this.$mq === 'mobileSmall' && !this.mobile;
        },
        mobile() {
            return this.$mq === 'mobile' && !this.tabletSmall;
        },
        tabletSmall() {
            return this.$mq === 'tabletSmall' && !this.tablet;
        },
        tablet() {
            return this.$mq === 'tablet' && !this.tabletLandscape;
        },
        tabletLandscape() {
            return this.$mq === 'tabletLandscape' && !this.laptopSmall;
        },
        laptopSmall() {
            return this.$mq === 'laptopSmall' && !this.laptop;
        },
        laptop() {
            return this.$mq === 'laptop' && !this.desktop;
        },
        desktop() {
            return this.$mq === 'desktop' && !this.desktopBig;
        },
        desktopBig() {
            return this.$mq === 'desktopBig';
        },
    }
}
