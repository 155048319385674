//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableMixin from "./mixin";
export default {
    mixins: [tableMixin],
    name: "TeenClothesTable",
    data: function () {
        return {
            tableData: {
                head: [
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle1'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle2'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle3'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle4'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle5'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle6'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle7'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle8'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle9'),
                    this.$t('sizeTable.childrenClothes.teenTable.headers.subtitle10'),
                ],
                body: {
                    inch: [
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle1'), '48.5 - 50.5"', '51 - 53"', '53.5 - 55"', '55.5 - 57.5"', '58 - 60"', '60.5 - 62"', '62.5 - 64.5"', '65 - 67"', '67.5 - 69.5"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle2'), '25"', '26.5"', '28"', '29.5"', '30.5"', '32.5"', '34"', '35"', '36"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle3'), '23"', '24"', '25"', '26"', '27"', '28"', '28.5"', '29.5"', '30"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle4'), '27"', '28"', '29.5"', '30.5"', '32"', '33.5"', '35"', '36"', '37"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle5'), '23.5"', '24.5"', '25.5"', '27"', '28"', '29"', '30"', '31"', '32.5"',],
                    ],
                    centimeter: [
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle1'), '123 - 128 cm', '129 - 134 cm', '135 - 140 cm', '141 - 146 cm', '147 - 152 cm', '153 - 158 cm', '159 - 164 cm', '165 - 170 cm', '171 - 176 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle2'), '64 cm', '67.5 cm', '71 cm', '74.5 cm', '78 cm', '82 cm', '86 cm', '89 cm', '91.5 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle3'), '59 cm', '61.5 cm', '63.5 cm', '66 cm', '68 cm', '70.5 cm', '73 cm', '74.5 cm', '76.5 cm'],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle4'), '68 cm', '71 cm', '74.5 cm', '77.5 cm', '81 cm', '85 cm', '89 cm', '91.5 cm', '94 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle5'), '59.5 cm', '62 cm', '65 cm', '68 cm', '70.5 cm', '73.5 cm', '76.5 cm', '79 cm', '82 cm',],
                    ]
                }
            }
        }
    }

}
