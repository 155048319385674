//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableMixin from "./mixin";
export default {
    mixins: [tableMixin],
    name: "ChildClothesTable",
    data: function () {
        return {
            tableData: {
                head: [
                    this.$t('sizeTable.childrenClothes.childTable.headers.subtitle1'),
                    this.$t('sizeTable.childrenClothes.childTable.headers.subtitle2'),
                    this.$t('sizeTable.childrenClothes.childTable.headers.subtitle3'),
                    this.$t('sizeTable.childrenClothes.childTable.headers.subtitle4'),
                    this.$t('sizeTable.childrenClothes.childTable.headers.subtitle5')
                ],
                body: {
                    inch: [
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle1'), '39 - 41"', '41.5 - 43.5"', '44 - 45.5"', '46 - 48"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle2'), '22"', '23"', '24"', '24.5"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle3'), '21"', '21.5"', '22"', '22.5"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle4'), '23.5"', '24.5"', '25"', '26"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle5'),'18"', '19.5"', '20.5"', '22"',]
                    ],
                    centimeter: [
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle1'), '99 - 104 cm', '105 - 110 cm', '111 - 116 cm', '117 - 122 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle2'), '56 cm', '58 cm', '60.5 cm', '63 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle3'), '54.5 cm', '55 cm', '56 cm', '57 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle4'), '60 cm', '62 cm', '64 cm', '66 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle5'), '45.5 cm', '49.5 cm', '52 cm', '56 cm',],
                    ]
                }
            }
        }
    }

}
