//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableMixin from "./mixin";
export default {
    mixins: [tableMixin],
    name: "KidClothesTable",
    data: function () {
        return {
            tableData: {
                head: [
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle1'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle2'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle3'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle4'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle5'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle6'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle7'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle8'),
                    this.$t('sizeTable.childrenClothes.kidTable.headers.subtitle9'),
                ],
                body: {
                    inch: [
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle1'), '20 - 22"', '22.5 - 24.5"', '25 - 27"', '27.5 - 29"', '29.5 - 31.5"', '32 - 34"', '34.5 - 36"', '36.5 - 38.5"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle2'), '15"', '17"', '18"', '18.5"', '19.5"', '20"', '20.5"', '21.5"'],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle3'), '15"', '17"', '17.5"', '18.5"', '19.5"', '19.5"', '20"', '20"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle4'), '14.5"', '16.5"', '17.5"', '18.5"', '19.5"', '20.5"', '21"', '22"',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle5'), '7.5"', '8.6"', '9.5"', '10.6"', '12.3"', '13.6"', '15"', '16"',],
                    ],
                    centimeter: [
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle1'), '51 - 56 cm', '57 - 62 cm', '63 - 68 cm', '69 - 74 cm', '75 - 80 cm', '81 - 86 cm', '87 - 92 cm', '93 - 98 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle2'), '38.5 cm', '43 cm', '45.5 cm', '47 cm', '49.5 cm', '51 cm', '52.5 cm', '54.5 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle3'), '38.5 cm', '43 cm', '45 cm', '46.5 cm', '49 cm', '50 cm', '50.5 cm', '52.5 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle4'), '37 cm', '42 cm', '45 cm', '47.5 cm', '50 cm', '51.5 cm', '53 cm', '56 cm',],
                        [this.$t('sizeTable.childrenClothes.kidTable.body.subtitle5'), '19.1 cm', '21.75 cm', '24.25 cm', '27 cm', '31.12 cm', '34.5 cm', '38 cm', '41 cm'],
                    ]
                }
            }
        }
    }

}
